@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;700&family=Space+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Source+Sans+3:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spline+Sans:wght@300;400;500;600;700&display=swap");
//Colors

//$primary-light: #00ffde;
//$primary-dark: #2ba595;
$primary-light: #112240;
$primary-dark: #6d30d2;
$secondary: #64ffda;
$secondary-dark: #26806b;
$dark-grey: #4e596f;
$light-navy: #e1e9ff;

//Header

$headerHeight: 100px;
$borderRadius: 12px;

//Fonts

$primaryFont: "Spline Sans", sans-serif;
$secondaryFont: "Source Sans 3", sans-serif;

//spacing

$spacing-md: 16px;
$spacing-lg: 32px;

//Breakpoints

@mixin breakpoint($point) {
  @if $point == md {
    @media (min-width: 48em) {
      @content;
    }
  }
}

//media-quieres

$mobileView: 476px;
$tabletView: 810px;

@mixin mobile {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin tabletPortraitUp {
  @media (min-width: 600px) and (max-width: 900px) {
    @content;
  }
}
@mixin tabletLandscapeUp {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin laptopUp {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin desktopUp {
  @media (min-width: 1800px) {
    @content;
  }
}
