@import "./src/styles/variables.scss";

.title {
  color: black;
  padding: 0 6rem;
  font-size: 32px;

  @include mobile {
    padding: 0 1rem;
    font-size: 28px;
  }
}

.blogsWrapper {
  display: flex;
  flex-direction: row;
  gap: 2em;
  padding: 2rem 6rem;
  flex-wrap: wrap;

  @include mobile {
    padding: 2rem 1rem;
    row-gap: 2em;
  }
}

.blogColumn {
  color: black;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 300px;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
  flex-grow: 1;

  & h3 {
    text-align: center;
  }

  &__blogAvatar {
    width: 100%;
    height: 70%;
    margin-top: -20px;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  & > span {
    background-color: black;
    margin-top: 3em;
    height: 2px;
    width: 100%;
  }

  &__logos {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 18px;

    & a {
      color: black;

      & :hover {
        cursor: pointer;
      }
    }
  }
}
