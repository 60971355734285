@import "../styles/variables.scss";

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: $primaryFont;
  width: 100%;
  color: $light-navy;
  height: 100vh;
  overflow-x: hidden;
  background-image: radial-gradient(
    circle,
    #ffffff,
    #f6f6f6,
    #ededed,
    #e4e4e4,
    #dbdbdb
  );
}

#root {
  height: 100vh;
}

.commonButton {
  cursor: pointer;
  outline: none;
  padding: 1.1rem 2.25rem;
  font-family: $primaryFont;
  margin: 0;
  background-color: #3500ff;
  border: 1px solid #3500ff;
  border-radius: $borderRadius;
  color: #ffffff;
  font-size: 14px;
  transition: 1s ease all;

  &:hover {
    background-color: #8800ff;
    border-color: transparent;
  }
}
