@import "./src/styles/variables.scss";

.homeWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 6rem;
  font-family: $primaryFont;
  background-color: transparent;

  @include mobile {
    flex-direction: column-reverse;
    padding: 1.5rem;
  }

  @include tabletPortraitUp {
    flex-direction: column-reverse;
    padding: 1.5rem;
  }

  &__firstContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5em;

    & h4,
    h3,
    h6,
    h5 {
      color: #000;
      width: 80%;

      @include mobile {
        width: 100%;
        font-weight: 400;
      }
    }

    & h1 {
      font-weight: 800;
      color: #000;
      font-size: 64px;
    }
    & h4 {
      font-weight: 500;
    }
    & h5 {
      font-weight: 600;
      transform: translateY(-4px);
      color: #3500ff;

      @include mobile {
        display: none;
      }
    }

    & div {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 2em;
    }
  }

  &__secondContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.spline {
  @include mobile {
    transform: scale(0.5) translateY(280px);
  }
  @include tabletPortraitUp {
    transform: scale(0.7);
  }

  @include laptopUp {
    transform: scale(1.2);
  }
}

.icon {
  font-size: 20px;
  transform: translateY(3px);
  color: #3500ff;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 1em;
  color: white;

  & :hover {
    cursor: pointer;
  }

  & div {
    background-color: white;
    border-radius: 10px;
    width: 150px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    padding: 1em 1em;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

    & h4 {
      color: black;
    }
  }

  & div:nth-child(1) {
    flex-direction: row-reverse;
  }
}
