@import "./src/styles/variables.scss";

.header {
  width: 100%;
  height: $headerHeight;
  padding: 0 1.5rem;

  @include breakpoint(md) {
    padding: 0 6rem;
  }
  transition: 0.3s ease all;
  position: fixed;
  top: 0;
  background: transparent;
  backdrop-filter: blur(8px);
  font-family: $secondaryFont;
  font-size: 15px;
  z-index: 1000;

  & hr {
    transform: translate(-3rem, 3.15rem);
    width: 4rem;
    color: #000;

    //  @media (max-width: 600px) {
    //     transform: translate(-3rem, 2.7rem);
    //   }
  }

  &__content {
    overflow: hidden;
    color: $light-navy;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    top: 1.5em;

    @media (max-width: $tabletView) {
      top: 2em;
      justify-content: space-between;
    }

    &__logo {
      width: 12em;
      color: #000;
      font-size: 1.4em;
      column-gap: 2rem;
      display: flex;
      flex-direction: row;
    }

    &__nav {
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #f4f4f4;
      transform: translate(0);
      transition: 0.3s ease transform;

      @include breakpoint(md) {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: $spacing-lg;
        font-family: $secondaryFont;
        font-weight: 600;
        @include breakpoint(md) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          margin-right: calc(0.5rem + #{$spacing-md});
        }
        li {
          &:not(:last-child) {
            margin-bottom: $spacing-lg;
            @include breakpoint(md) {
              margin-bottom: 0;
              margin-right: $spacing-md;
            }
          }
          a {
            text-decoration: none;
            color: rgba(#000, 0.8);
            padding: 0.75rem 1.25rem;
            border-radius: $borderRadius;
            transition: 0.3s ease all;
            display: flex;
            flex-direction: row;
            justify-content: center;
            line-height: 2;

            @media (max-width: $tabletView) {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              line-height: 2;
              color: #fff;
            }

            & span:first-of-type {
              color: #000;
            }

            & span {
              color: #000;
              font-family: $primaryFont;
              &:hover {
                color: #3500ff;
              }

              @media (max-width: $mobileView) {
                color: #fff;
                padding-left: 2px;
              }
            }
          }
        }
      }
      &.isMenu {
        transform: translate(100%);
      }
      &.isMenuMobile {
        transform: none;
      }
    }

    &__toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 2rem;
      transition: 0.3s ease all;
      position: relative;
      color: black;

      &:hover {
        color: #2e00e6;
      }

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}

.logo1 {
  &:hover {
    color: #3500ff;
    cursor: pointer;
    transform: translateY(5px);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
}

.logo2 {
  &:hover {
    color: #3500ff;
    cursor: pointer;
    transform: translateY(5px);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
}

.logo3 {
  &:hover {
    color: #3500ff;
    cursor: pointer;
    transform: translateY(5px);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
}

.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: #3500ff;
  transform-origin: 0%;
}
