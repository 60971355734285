@import "./src/styles/variables.scss";

.skillsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: transparent;
  padding: 0 3rem;

  @include mobile {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    padding: 1em 1em;
  }

  &__skills {
    width: 100%;
    overflow: visible;
    height: 150px;
    margin: auto;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    @include mobile {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    & div {
      display: flex;
      justify-content: center;
    }
  }
}

.icon {
  color: #00000060;
  font-size: 4em;
  display: flex;
  justify-self: center;
  align-self: center;
  transition: 0.5s ease-in-out;

  &:hover {
    transform: scale(1.1);
    fill: url(#my-cool-gradient);
    color: #3500ff;
  }

  @include mobile {
    font-size: 2em;
  }
}
